<template>
  <section class="content">
    <div class="row">
      <div class="col-12">
        <recipe-step-edit v-if="stepEditFormIsVisible"
                          :categories="categories"
                          :initial="step"
                          :step-number="stepId"
                          :recipe="recipe"
                          :items="recipeYields"
                          :step-timers="stepTimers"
                          @goBack="goBack"
        />
        <recipe-edit
          v-else-if="showEditRecipeForm"
          :categories="categories"
          :recipe-yields="recipeYields"
          :initial="recipe"
          @showRecipePreview="showRecipePreview"
          @recipeUpdated="recipeUpdated"
          :items="recipeYields"
        />
        <recipe-step-create
          v-else-if="showStepCreateForm"
          :step-timers="stepTimers"
          :categories="categories"
          :items="recipeYields"
          :initial="recipe"
          :create-single-step="true"
        />
        <div class="card mt-2 recipe-preview" v-else>
          <div class="card-header d-flex justify-content-between align-items-center">
            <h2 class="card-title">Recipe Details</h2>
            <div class="ml-auto">
              <button class="btn btn-secondary " @click="closePreview">Back</button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-3 col-lg-4">
                <div>
                  <div class="form-group img-wrapper" id="img-wrapper">
                    <img :src="recipe.is_catalog_image ? recipe.catalog.image : recipe.image" width="386px;" alt="recipe_image" />
                  </div>
                </div>
              </div>
              <div class="col-xl-9 col-lg-8">
                <div>
                  <div class="row align-items-center my-2 px-2">
                    <h3 class="recipe-name">{{ recipe.name }}</h3>
                    <button class="btn btn-primary ml-auto" v-if="showRecipeEditButton" @click="editRecipe">
                      <i class="fas fa-edit"></i>
                    </button>
                  </div>
                  <div class="bg-alice-blue p-4">
                    <table class="table ingredient-table">
                      <thead>
                        <tr>
                          <th scope="col">Category</th>
                          <th scope="col">Estimated Time</th>
                          <th scope="col">Recipe Yield</th>
                          <th scope="col">UOM</th>
                          <th scope="col">Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ recipe.recipe_category.name }}</td>
                          <td>{{ recipe.estimated_time }}</td>
                          <td>
                            <p v-for="(recipe_yields, index) of recipe.recipe_yields" :key="index">
                              {{ recipe_yields.catalog.name }}
                            </p>
                          </td>
                          <td>
                            <p v-for="(recipe_yields, index) of recipe.recipe_yields" :key="index">
                              {{ getUnitOfMeasure(recipe_yields.catalog, recipe_yields.variation_data_id) }}
                            </p>
                          </td>
                          <td>
                            <p v-for="(recipe_yields, index) of recipe.recipe_yields" :key="index">
                              {{ recipe_yields.quantity }}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="recipe-details my-3">
                    <p>{{ recipe.description }}</p>
                  </div>
                </div>
              </div>
            </div>
            <draggable v-model="recipe.steps" @change="changeStepOrder">
              <transition-group>
                <div class="row" v-for="(recipeStep, index) in recipe.steps " :key="recipeStep.id">
                  <div class="col-12">
                    <button type="button" @click="showStepDetail(index)" class="btn btn-secondary w-100 recipe-btn mb-3">
                      <span>{{ 'Step ' + ++index + ': ' + recipeStep.title }}</span>
                    </button>
                  </div>
                </div>
              </transition-group>
            </draggable>
            <!-- using showAddStepButton Prop to hide add step button in recipe preview -->
            <div class="row d-flex justify-content-end" v-if="showAddStepButton">
              <div class="col-2">
                <button type="button" @click="addStep(recipe)" class="btn btn-primary w-100 mb-3">
                  <span>Add Step</span>
                </button>
              </div>
            </div>
          </div>

          <recipe-step-detail v-if="stepDetailModalIsVisible"
                              :show-modal="stepDetailModalIsVisible"
                              :initial="recipe"
                              :step-id="stepId"
                              :ingredients="recipeYields"
                              :step-timers="stepTimers"
                              @closeStepDetailModal="closeStepDetailModal"
                              @showStepEditForm="showStepEditForm"
                              @stepDeleted="stepDeleted"
                              :show-recipe-edit-button="showRecipeEditButton"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Form from 'laravel-form-validation';
import RecipeStepDetail from '@/js/KitchenHand/Components/Recipe/RecipeStepDetail';
import RecipeStepEdit from '@/js/KitchenHand/Components/Recipe/RecipeStepEdit';
import draggable from 'vuedraggable';
import RecipeEdit from '@/js/KitchenHand/Components/Recipe/RecipeEdit';

export default {
  name: 'RecipePreview',
  components: {
    RecipeStepDetail,
    RecipeStepEdit,
    draggable,
    RecipeEdit,
    RecipeStepCreate: () => import('./RecipeStepCreate.vue'),
  },
  props: {
    initial: {
      type: Object,
    },
    recipeYields: {
      type: Array,
    },
    categories: {
      type: Array,
    },
    showRecipeEditButton: {
      type: Boolean,
    },
    stepTimers: {
      props: Object,
    },
    showAddStepButton: {
      type: Boolean,
    }
  },
  data() {
    return {
      id: '',
      form: new Form,
      recipe: this.initial,
      stepId: '',
      stepDetailModalIsVisible: false,
      stepEditFormIsVisible: false,
      step: { } ,
      showEditRecipeForm: false,
      showStepCreateForm: false,
    }
  },
  methods: {
    showStepDetail(stepId) {
      this.stepId = --stepId;
      this.stepDetailModalIsVisible = true;
    },
    closeStepDetailModal() {
      this.stepDetailModalIsVisible = false;
    },
    closePreview() {
      this.$emit('closeRecipePreview');
    },
    showStepEditForm(value) {
      this.step = value;
      this.stepEditFormIsVisible = true;
    },
    changeStepOrder() {
      this.$http.put(route('admin.kitchen.step-order-change', this.recipe.id) ,{
        'steps' : this.recipe.steps
      })
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          console.log(error)
        });
    },
    stepDeleted(stepId) {
      this.recipe.steps.splice(stepId,1);
    },
    goBack() {
      this.stepEditFormIsVisible = this.stepDetailModalIsVisible = false
      this.$http.get(route('admin.kitchen.recipe.show', this.initial.id))
        .then((response) => {
          this.recipe = response.data.recipe
        })
    },
    editRecipe() {
      this.showEditRecipeForm = true
    },
    showRecipePreview(recipe) {
      this.showEditRecipeForm = false
    },
    recipeUpdated() {
      this.$http.get(route('admin.kitchen.recipe.show', this.recipe.id))
        .then((response) => {
          this.recipe = response.data.recipe
          this.showEditRecipeForm = false
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.showEditRecipeForm = true;
        }
        )
    },
    getUnitOfMeasure(catalog, variationDataId) {
      let variationsData = catalog.variations_data.find((uom) => uom.id === variationDataId)
      if (variationsData !== undefined) {
        // for checking square item having parent child
        if (/[,\-]/.test(variationsData.item_variation_data['name'])){
          variationsData.item_variation_data['name'] = variationsData.item_variation_data['name'].substring(
              variationsData.item_variation_data['name'].indexOf('-') + 2
          )
        }
        return variationsData.item_variation_data['name']
      }
    },
    addStep(recipe) {
      this.recipe = recipe;
      this.showStepCreateForm = true;
    }
  }
};
</script>

<style scoped>
  .add-recipe .card-header {
    padding: 1rem 1.2875rem;
  }
  .bg-alice-blue {
    background-color: #F8FBFF;
  }
  .ingredient-table th {
    border: none;
    font-size: 1rem;
  }

  .recipe-name {
    max-width: 500px;
  }
  .recipe-details p{
    font-size: 1.25rem;
  }
  .btn-secondary.recipe-btn {
    position: relative;
    text-align: left;
    background-color: #e5e5e5;
    border-color: #e5e5e5;
    color: #000;
    font-size: 1.2rem;
  }
  .recipe-btn span {
    padding: 0 30px;
  }
  .recipe-btn span::before, .recipe-btn span::after {
    position: absolute;
    font-family: "Font Awesome 5 Free";
  }
  .recipe-btn span::before {
    content: "\f058";
    left: 15px;
    color: #008700;
  }
  .recipe-btn span::after {
    content: "\f105";
    right: 15px;
  }

</style>
